import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

/* Layout */
import Layout from "@/layout";

export const asyncRoutes = [
  {
    path: "/test",
    component: () => import("@/views/test/index"),
    hidden: true,
  },
];

export const constantRoutes = [
  {
    path: "/test",
    component: () => import("@/views/test/index"),
    hidden: true,
  },
  {
    path: "/draggable",
    component: () => import("@/views/test/draggable.vue"),
    hidden: true,
  },
  {
    path: "/testTable",

    component: () => import("@/views/test/draggableTable.vue"),
    hidden: true,
  },
  {
    path: "/afCustomView",
    component: () => import("@/views/e-af-custom-table/onlyView/onlyViewIndex"),
    name: "自定义报告列浏览",
    meta: { title: "自定义报告浏览" },
  },
  {
    path: "/chargpt/image",
    component: () => import("@/views/chatgpt/image/index"),
    hidden: true,
  },
  {
    path: "/chargpt/uploadImage",
    component: () => import("@/views/chatgpt/upload/uploadIndex"),
    hidden: true,
  },


  {
    path: "/chatgpt/image",
    component: () => import("@/views/chatgpt/image/index"),
    hidden: true,
  },
  {
    path: "/chatgpt/listRobot",
    component: () => import("@/views/chatgpt/robot/list/listIndex"),
    hidden: true,
  },
  {
    path: "/chatgpt/uploadImage",
    component: () => import("@/views/chatgpt/upload/uploadIndex"),
    hidden: true,
  },
  {
    path: "/redirect",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "/redirect/:path(.*)",
        component: () => import("@/views/redirect/index"),
      },
    ],
  },
  {
    path: "/login",
    component: () => import("@/views/login/index"),
    hidden: true,
  },
  {
    path: "/silentSignIn",
    component: () => import("@/views/login/silentSignin"),
    hidden: true,
  },
  {
    path: "/auth-redirect",
    component: () => import("@/views/login/auth-redirect"),
    hidden: true,
  },
  {
    path: "/404",
    component: () => import("@/views/error-page/404"),
    hidden: true,
  },
  {
    path: "/site/index",
    component: () => import("@/views/site/home/index"),
    hidden: true,
  },
  {
    path: "/401",
    component: () => import("@/views/error-page/401"),
    hidden: true,
  },
  {
    path: "/402",
    component: () => import("@/views/error-page/402"),
    hidden: true,
  },

  {
    path: "/",
    component: Layout,
    redirect: "/dashboard",
    meta: { title: "概览" },
    name: "Dashboard",
    children: [
      {
        path: "dashboard",
        component: () => import("@/views/dashboard/index"),
        name: "概览",
      },
      {
        path: "shopifyDashboard",
        component: () => import("@/views/dashboard/components/exhibitionC.vue"),
        name: "概览 ",
      },
    ],
  },
  {
    path: "/import",
    component: Layout,
    name: "facebook导入",
    meta: {
      title: "facebook导入",
    },
    children: [
      {
        path: "facebook/template/:operateType/:id",
        component: () => import("@/views/import/facebook/index.vue"),
        name: "详情",
      },
    ],
  },
  {
    path: "/campaign",
    component: Layout,
    alwaysShow: true, // will always show the root menu
    name: "广告管理",
    meta: {
      title: "广告管理",
    },
    children: [
      {
        path: "admin",
        component: () => import("@/views/campaign/admin/index.vue"),
        name: "投放管理",
        meta: {
          title: "投放管理",
        },
      },
      //tiktokBatch
      {
        path: "/tiktokBatch/campaign",
        component: () => import("@/views/tiktokBatch/campaign"),
      },
      {
        path: "/tiktokBatch/adgroup-traffic",
        component: () => import("@/views/tiktokBatch/adgroup-traffic"),
      },
      {
        path: "/tiktokBatch/adgroup-product-sale",
        component: () => import("@/views/tiktokBatch/adgroup-product-sale.vue"),
      },
      {
        path: "/tiktokBatch/adgroup-cover",
        component: () => import("@/views/tiktokBatch/adgroup-cover"),
      },
      {
        path: "/tiktokBatch/app_promotion_spc",
        component: () => import("@/views/tiktokBatch/app_promotion_spc"),
      },
      {
        path: "/tiktokBatch/ad",
        component: () => import("@/views/tiktokBatch/ad"),
      },
      {
        path: "/tiktokBatch/ad-product-sale",
        component: () => import("@/views/tiktokBatch/ad-product-sale.vue"),
      },
      {
        path: "/tiktokBatch/ad_spc",
        component: () => import("@/views/tiktokBatch/ad_spc"),
      },

      // tikkot
      {
        path: "/tiktok/update/campaign",
        component: () => import("@src/views/tiktokUpdate/campaign"),
      },
      {
        path: "/tiktok/update/campaign-spc",
        component: () => import("@src/views/tiktokUpdate/campaign-spc"),
      },
      {
        path: "/tiktok/update/adset-app",
        component: () => import("@src/views/tiktokUpdate/adset-app"),
      },
      {
        path: "/tiktok/update/adset-convert",
        component: () => import("@src/views/tiktokUpdate/adset-convert"),
      },
      {
        path: "/tiktok/update/adset-cover",
        component: () => import("@src/views/tiktokUpdate/adset-cover"),
      },
      {
        path: "/tiktok/update/ad",
        component: () => import("@src/views/tiktokUpdate/ad"),
      },
      //google
      {
        path: "/google",
        component: () => import("@/views/e-create-editor/google/index.vue"),
        name: "google创编",
        redirect: "/google/create",
        meta: {
          title: "google创编",
        },
        children: [
          {
            path: "create",
            component: () =>
              import("@/views/e-create-editor/google/create.vue"),
            name: "",
            meta: {
              title: "google广告系列",
            },
          },
          {
            path: "ad-series",
            component: () =>
              import("@/views/e-create-editor/google/ad-series.vue"),
            name: "",
            meta: {
              title: "google广告组",
            },
          },
          {
            path: "ad-ads",
            component: () => import("@/views/e-create-editor/google/ads.vue"),
            name: "google广告",
            meta: {
              title: "google广告",
            },
          },

          // {
          //   path: "googleLibrary",
          //   component: () => import("@/views/e-create-editor/google/components/googleLibrary.vue"),
          //   name: "google广告",
          //   meta: {
          //     title: "google广告",
          //   },
          // },
        ],
      },
      // snapchat start
      {
        path: "/snapchat",
        component: () =>
          import("@/views/e-create-editor/snapchat/indexList.vue"),
        name: "snapchat创编",
        redirect: "/snapchat/create",
        meta: {
          title: "snapchat创编",
        },
        children: [
          {
            path: "create",
            component: () =>
              import("@/views/e-create-editor/snapchat/createIndex.vue"),
            name: "",
            meta: {
              title: "snapchat广告系列",
            },
          },
          {
            path: "ad-series",
            component: () =>
              import("@/views/e-create-editor/snapchat/ad-series.vue"),
            name: "",
            meta: {
              title: "snapchat广告组",
            },
          },
          {
            path: "ad-ads",
            component: () => import("@/views/e-create-editor/snapchat/adsIndex.vue"),
            name: "snapchat广告",
            meta: {
              title: "snapchat广告",
            },
          },
        ],
      },
      // snapchat end
      {
        path: "/google/single-copy-ads",
        component: () =>
          import("@/views/e-create-editor/google/single-copy-ads.vue"),
        name: "",
        meta: {
          title: "google广告组",
        },
      },
      // 创编第二个版本
      {
        path: "/creation/list",
        component: () => import("@/views/e-create-editor/list/index.vue"),
        name: "素材批量",
        meta: {
          title: "素材批量",
        },
      },
      {
        path: "/tiktok/material-good-select",
        component: () =>
          import("@/views/tiktokMaterialGoodSelection/index.vue"),
        name: "TikTok素材优选",
        meta: {
          title: "TikTok素材优选",
        },
      },
      {
        path: "/creation/details",
        component: () => import("@/views/e-create-editor/details/index.vue"),
        name: "模板数据列表",
        meta: {
          title: "模板数据列表",
        },
      },
      {
        path: "/creation/google/v2",
        component: () => import("@/views/e-create-editor/google/v2/index.vue"),
        name: "google创建",
        meta: {
          title: "google创建",
        },
      },
      {
        path: "/creation/google/templateEdit",
        component: () =>
          import("@/views/e-create-editor/google/v2/templateEdit.vue"),
        name: "google模版编辑",
        meta: {
          title: "google模版编辑",
        },
      },
      {
        path: "/creation/google/detailsEdit",
        component: () =>
          import("@/views/e-create-editor/google/v2/detailsEdit.vue"),
        name: "google详情编辑",
        meta: {
          title: "google详情编辑",
        },
      },

      {
        path: "/fb/template/:operateType/:id",
        name: "Facebook模版编辑",
        component: () => import("@/views/e-create-editor/facebook/index.vue"),
        meta: {
          title: "Facebook 模版编辑",
        },
      },
      {
        path: "/fb/instance/:operateType/:id",
        name: "facebook编辑",
        component: () => import("@/views/e-create-editor/facebook/index.vue"),
        meta: {
          title: "Facebook 编辑",
        },
      },
      {
        path: "/tiktok/template/:operateType/:id",
        name: "Tiktok模版编辑",
        component: () => import("@/views/e-create-editor/tiktok/index.vue"),
        meta: {
          title: "Tiktok 模版编辑",
        },
      },
      {
        path: "/tiktok/instance/:operateType/:id",
        name: "Tiktok编辑",
        component: () => import("@/views/e-create-editor/tiktok/index.vue"),
        meta: {
          title: "Tiktok 编辑",
        },
      },

      // facebook
      {
        path: "/facebook/addAdset",
        component: () => import("@src/views/facebook/step2/addAdset.vue"),
      },
      {
        path: "/facebook/addAd",
        component: () => import("@src/views/facebook/step3/addAd.vue"),
      },
      {
        path: "/facebook/step1",
        component: () => import("@src/views/facebook/step1"),
      },
      {
        path: "/facebook/estep1",
        component: () => import("@src/views/facebook/step1/edit"),
      },
      {
        path: "/facebook/cstep1",
        component: () => import("@src/views/facebook/step1/copy"),
      },
      {
        path: "/facebook/step2",
        component: () => import("@src/views/facebook/step2"),
      },
      {
        path: "/facebook/astep2",
        component: () => import("@src/views/facebook/step2/index2"),
      },

      {
        path: "/facebook/estep2",
        component: () => import("@src/views/facebook/step2/edit.vue"),
      },
      {
        path: "/facebook/cstep2",
        component: () => import("@src/views/facebook/step2/copy.vue"),
      },
      {
        path: "/facebook/step3",
        component: () => import("@src/views/facebook/step3/index.vue"),
      },
      {
        path: "/facebook/estep3",
        component: () => import("@src/views/facebook/step3/edit.vue"),
      },
      {
        path: "/facebook/cstep3",
        component: () => import("@src/views/facebook/step3/copy"),
      },

      // facobook end
      {
        path: "/batchad",
        component: () => import("@/views/batchad/index.vue"),
        name: "fb素材批量",
        meta: {
          title: "fb素材批量",
        },
      },
      {
        path: "/temp",
        component: () => import("@/views/batchad/temp/index.vue"),
        name: "素材",
        meta: {
          title: "素材",
        },
      },
      //copy
      {
        path: "/tiktok/copy/campaign",
        component: () => import("@src/views/tiktokUpdate/copy/campaign"),
      },
      {
        path: "/tiktok/copy/adset-app",
        component: () => import("@src/views/tiktokUpdate/copy/adset-app"),
      },
      {
        path: "/tiktok/copy/adset-convert",
        component: () => import("@src/views/tiktokUpdate/copy/adset-convert"),
      },
      {
        path: "/tiktok/copy/ad",
        component: () => import("@src/views/tiktokUpdate/copy/ad"),
      },
      {
        path: "/e-early-warning/list",
        component: () => import("@/views/e-early-warning/list/listIndex.vue"),
        name: "警告列表",
        meta: {
          title: "警告列表",
        },
      },
      {
        path: "/e-early-warning/create",
        component: () =>
          import("@/views/e-early-warning/create/createIndex.vue"),
        name: "创建警告规则",
        meta: {
          title: "创建警告规则",
        },
      },
      {
        path: "/wechatwarning/admin",
        component: () => import("@/views/wechatwarning/admin.vue"),
        name: "警告处理",
        meta: {
          title: "警告处理",
        },
      },
      {
        path: "/wechatwarning/create",
        component: () => import("@/views/wechatwarning/create.vue"),
        name: "预警设置",
        meta: {
          title: "预警设置",
        },
      },
      {
        path: "/wechatwarning/edit/:id",
        component: () => import("@/views/wechatwarning/edit.vue"),
        name: "预警编辑",
        meta: {
          title: "预警编辑",
        },
      },
      {
        path: "/operation/log",
        component: () => import("@/views/report/log/admin.vue"),
        name: "操作日志",
        meta: {
          title: "操作日志",
        },
      },
    ],
  },
  {
    path: "/admaterialParent",
    component: Layout,
    alwaysShow: true, // will always show the root menu
    name: "创意管理",
    meta: {
      title: "创意管理",
    },
    children: [
      {
        path: "/digitalHuman",
        component: () => import("@/views/e-digitalHuman/listIndex.vue"),
        meta: { title: "数字人" },
        name: "数字人",
      },
      {
        path: "/admaterial",
        component: () => import("@/views/admaterial/index.vue"),
        name: "素材管理",
        meta: {
          title: "素材管理",
        },
      },
      {
        path: "/material-authority",
        component: () => import("@/views/e-originality/material-authority"),
        name: "素材权限",
        meta: {
          title: "素材权限",
        },
      },
      {
        path: "/folder-manage",
        component: () => import("@/views/e-originality/folder-manage"),
        name: "文件夹管理",
        meta: {
          title: "文件夹管理",
        },
      },
      {
        path: "/tagsManage",
        component: () => import("@/views/tagsManage/index.vue"),
        name: "标签管理",
        meta: {
          title: "标签管理",
        },
      },
      {
        path: "/adLot",
        component: () => import("@/views/e-ad-lot/excelList/index.vue"),
        name: "广告批量",
        meta: {
          title: "广告批量",
        },
      },
      {
        path: "/adLot/batchList",
        component: () => import("@/views/e-ad-lot/batchList/index.vue"),
        name: "广告批量详情",
        meta: {
          title: "广告批量详情",
        },
      },
      {
        path: "/e-shopify/list",
        component: () => import("@/views/e-shopify/list/index.vue"),
        name: "shopify列表",
        meta: {
          title: "shopify列表",
        },
      },
      {
        path: "/dtc-business-settlement/list",
        component: () => import("@/views/dtc-business-settlement/list/index.vue"),
        name: "DTC业务结算数据表",
        meta: {
          title: "DTC业务结算数据表",
        },
      },
      {
        path: "/webhook/create",
        component: () => import("@/views/e-shopify/webhook/create/index.vue"),
        meta: { title: "webhook" },
        name: "webhook",
      },
      {
        path: "/webhook/list",
        component: () => import("@/views/e-shopify/webhook/list/index.vue"),
        meta: { title: "店铺绑定" },
        name: "webhook-list",
      },
      {
        path: "/e-store-sign/list",
        component: () => import("@/views/e-store-sign/public_list/index.vue"),
        name: "商铺看板列表",
        meta: {
          title: "商铺看板列表",
        },
      },
      {
        path: "/e-store-sign/mad_list",
        component: () => import("@/views/e-store-sign/list/index.vue"),
        name: "商铺看板列表 ",
        meta: {
          title: "商铺看板列表",
        },
      },
      {
        path: "/e-invitation/list",
        component: () => import("@/views/e-invitation/list/listIndex.vue"),
        name: "授权码绑定",
        meta: {
          title: "授权码绑定",
        },
      },
    ],
  },
  {
    path: "/e-shopify",
    component: Layout,
    name: "电商报表",
    redirect: "/e-shopify/orderList",
    meta: {
      title: "电商报表",
    },
    children: [
      {
        path: "/e-shopify/orderList",
        component: () => import("@/views/e-shopify/list2/orderList.vue"),
        name: "订单列表",
        meta: {
          title: "订单列表",
        },
      },
      {
        path: "/e-shopify/orderAnalyze",
        component: () => import("@/views/e-shopify/list2/adList.vue"),
        name: "订单分析",
        meta: {
          title: "订单分析",
        },
      },
      {
        path: "/e-shopify/placeAnalyze",
        component: () => import("@/views/e-shopify/list2/analyst.vue"),
        name: "投放分析 ",
        meta: {
          title: "投放分析",
        },
      },
      {
        path: "/e-shopify/summaryBoard",
        component: () => import("@/views/e-shopify/list2/summaryList.vue"),
        name: "汇总看板",
        meta: {
          title: "汇总看板",
        },
      },
      {
        path: "/e-shopify/adAnalyze",
        component: () => import("@/views/e-shopify/list2/adAnalyses.vue"),
        name: "广告分析",
        meta: {
          title: "广告分析",
        },
      },
    ],
  },
  {
    path: "/analysis/",
    component: Layout,
    meta: { title: "投放分析" },
    name: "投放分析",
    children: [
      {
        path: "/analysis/admin",
        component: () => import("@/views/analysis/admin"),
        name: "",
      },
    ],
  },
  {
    path: "/effect/",
    component: Layout,
    meta: { title: "效果报表" },
    name: "效果报表 ",
    children: [
      {
        path: "/afCustomList",
        component: () => import("@/views/e-af-custom-table/list/reportIndex"),
        name: "自定义报告列表",
        meta: { title: "自定义报告列表" },
      },
      {
        path: "/afCustomCreate",
        component: () => import("@/views/e-af-custom-table/create/createIndex"),
        name: "自定义报告列创建",
        meta: { title: "自定义报告创建" },
      },

      {
        path: "/dataPivot/appsflyer/create",
        component: () => import("@/views/dataPivot/appsflyer/create"),
        name: "数据透视表创建",
        meta: { title: "数据透视表创建" },
      },
      {
        path: "/dataPivot/appsflyer/look",
        // component: () => import("@/views/dataPivot/appsflyer/look"),
        component: () => import("@/views/report/originality/appsflyer.vue"),
        name: "数据透视表查看",
        meta: { title: "数据透视表查看" },
      },

      {
        path: "/userDefined/admin",
        component: () => import("@/views/report/userDefined/admin"),
        name: "数据透视表",
        meta: { title: "数据透视表" },
      },
      {
        path: "/userDefined/rulesTableDetail",
        component: () => import("@/views/report/userDefined/rulesTableDetail"),
        name: "自定义报表规则详情表单",
        meta: { title: "自定义报表规则详情表单" },
      },
      {
        path: "/targets/admin",
        // component: () => import("@/views/report/targets/admin"),
        component: () => import("@/views/error-page/401"),
        name: "受众包报表",
        meta: { title: "受众包报表" },
      },
      {
        path: "/originality/admin",
        component: () => import("@/views/report/originality/admin"),
        name: "创意报表",
        meta: { title: "创意报表" },
      },
      {
        path: "/originality/effect",
        component: () => import("@/views/report/originality/effect.vue"),
        name: "效果报表数据",
        meta: { title: "效果报表数据" },
      },
      {
        path: "/originality/cohort",
        component: () => import("@/views/report/originality/cohort"),
        name: "cohort报表",
        meta: { title: "cohort报表" },
      },
      {
        path: "/originality/hour",
        component: () => import("@/views/report/hour/index.vue"),
        name: "小时报表",
        meta: { title: "小时报表" },
      },
      {
        path: "/geo/admin",
        // component: () => import("@/views/report/geo/admin"),
        component: () => import("@/views/error-page/401"),
        name: "GEO报表",
        meta: { title: "GEO报表" },
      },
      {
        path: "/orient/admin",
        // component: () => import("@/views/report/orient/admin"),
        component: () => import("@/views/error-page/401"),
        name: "定向报表",
        meta: { title: "定向报表" },
      },
    ],
  },
  {
    path: "/pangleParent",
    component: Layout,
    meta: { title: "资源库" },
    name: "资源库",
    children: [
      {
        path: "/pangle",
        component: () => import("@/views/report/pangle/admin"),
        meta: { title: "Pangle屏幕列表" },
        name: "Pangle屏幕列表",
      },
      {
        path: "/audience/admin",
        component: () => import("@/views/audience/admin"),
        meta: { title: "受众库" },
        name: "受众库",
      },
      {
        path: "/audience/create/:type",
        component: () => import("@/views/audience/create"),
        meta: { title: "创建受众库" },
        name: "创建受众库",
      },
      {
        path: "/text/admin",
        component: () => import("@/views/text/admin"),
        meta: { title: "文本库" },
        name: "文本库",
      },
      {
        path: "/text/edit",
        component: () => import("@/views/text/edit"),
        meta: { title: "文本库编辑" },
        name: "文本库编辑",
      },
      {
        path: "/text/create",
        component: () => import("@/views/text/create"),
        meta: { title: "文本库创建" },
        name: "文本库创建",
      },
    ],
  },
  {
    path: "/mypackage/",
    component: Layout,
    meta: { title: "设置" },
    name: "设置",
    children: [
      {
        path: "/mypackage/admin",
        component: () => import("@/views/mypackage/admin"),
        meta: { title: "我的套餐" },
        name: "我的套餐",
      },
      {
        path: "/originalityCreatRules/admin",
        component: () => import("@/views/report/originalityCreatRules/admin"),
        meta: { title: "自定义设置" },
        name: "自定义设置",
      },
      {
        path: "/app/admin",
        component: () => import("@/views/app/admin"),
        meta: { title: "我的产品" },
        name: "我的产品",
      },
      {
        path: "/app/create",
        component: () => import("@/views/app/create"),
        meta: { title: "创建我的产品" },
        name: "创建我的产品",
      },
      {
        path: "/app/edit",
        component: () => import("@/views/app/edit"),
        meta: { title: "编辑我的产品" },
        name: "编辑我的产品",
      },
      {
        path: "/user/settings",
        component: () => import("platform/user/settings"),
        meta: { title: "账号设置" },
        name: "账号设置",
      },
      {
        path: "/advertiser/admin",
        component: () => import("@/views//advertiser/admin"),
        meta: { title: "账户分组管理" },
        name: "账户分组管理",
      },
      {
        path: "/advertiser/create",
        component: () => import("@/views//advertiser/create"),
        meta: { title: "账户分组管理创建" },
        name: "账户分组管理创建",
      },
      {
        path: "/advertiser/edit",
        component: () => import("@/views//advertiser/edit"),
        meta: { title: "账户分组管理编辑" },
        name: "账户分组管理编辑",
      },
      {
        path: "/account/admin",
        component: () => import("@/views/account/list/admin"),
        meta: { title: "媒体账户" },
        name: "媒体账户",
        children: [
          // child with absolute path. we need to add an `id` because the parent needs it
          { path: "/account/admin/:id", name: "子账户" },
        ],
      },
      {
        path: "/account/transfer",
        component: () => import("@/views/account/transfer/index"),
        meta: { title: "中转" },
        name: "中转",
      },

      {
        path: "/account/facebook/:fid/:bid",
        component: () => import("@/views/account/facebook/admin"),
      },
      {
        path: "/account/google/:id/:gid",
        component: () => import("@/views/account/google/admin"),
      },
      {
        path: "/account/twitter/:id",
        component: () => import("@/views/account/twitter/twitter"),
      },
      {
        path: "/account/tiktok/:id/:tid",
        component: () => import("@/views/account/tiktok/admin"),
      },
      {
        path: "/account/unity/:id",
        component: () => import("@/views/account/unity/admin"),
      },
      {
        path: "/account/apple/:id/:mid",
        component: () => import("@/views/account/apple/admin"),
      },
      {
        path: "/account/moloco/:workplace_id",
        component: () => import("@/views/account/moloco/admin"),
      },
      {
        path: "/account/snapchat/:oid/:sid",
        component: () => import("@/views/account/snapchat/admin"),
      },
      {
        path: "/account/ironsource/:id",
        component: () => import("@/views/account/ironsource/admin"),
      },
      {
        path: "/account/mintegral/:id",
        component: () => import("@/views/account/mintegral/admin"),
      },
      {
        path: "/third/admin",
        component: () => import("@/views/report/thirdFieldAuth/admin"),
        meta: { title: "第三方设置" },
        name: "第三方设置",
      },
      {
        // 数据上传
        path: "/dataupload",
        component: () => import("@/views/dataupload/index"),
        meta: { title: "数据上传" },
        name: "数据上传",
      },
      {
        path: "/api/admin",
        component: () => import("@/views/api/admin"),
        meta: { title: "API权限" },
        name: "API权限",
      },
    ],
  },
  {
    path: "/userdataParent",
    component: Layout,
    meta: { title: "系统管理" },
    name: "系统管理",
    children: [
      {
        path: "/userdata",
        component: () => import("@/views/userdata/index"),
        redirect: "/userdata/dashboard",
        meta: { title: "用户数据" },
        name: "用户数据",
        children: [
          // empty child
          {
            path: "/userdata/dashboard",
            component: () => import("@/views/userdata/dashboard/index"),
            meta: { title: "" },
            name: "",
          },
          // child with absolute path. we need to add an `id` because the parent needs it
          {
            path: "/userdata/datasummary",
            component: () => import("@/views/userdata/datasummary/index"),
            meta: { title: "" },
            name: "",
          },
        ],
      },

      {
        path: "/thirdPartyAuthApproval/admin",
        component: () => import("@/views/thirdPartyAuthApproval/admin"),
        meta: { title: "第三方授权审批" },
        name: "第三方授权审批",
      },
      {
        path: "/contact/index",
        component: () => import("@/views/contact/index"),
        meta: { title: "公众号申请表单" },
        name: "公众号申请表单",
      },

      {
        path: "/modules/admin",
        component: () => import("@/views/modules/admin"),
        meta: { title: "模块管理" },
        name: "模块管理 ",
      },
      {
        path: "/modules/edit",
        component: () => import("@/views/modules/edit"),
        meta: { title: "模块管理" },
        name: "模块管理",
      },
      // 账号管理
      {
        path: "/user/adminInfo",
        component: () => import("@/views/user/adminInfo"),
        meta: { title: "账号管理" },
        name: "账号管理",
      },
      {
        path: "/user/myProject",
        component: () => import("@/views/myProject/index.vue"),
        meta: { title: "我的项目" },
        name: "我的项目",
      },
      {
        path: "/user/myProject/create",
        component: () => import("@/views/myProject/create.vue"),
        meta: { title: "新建项目" },
        name: "新建项目",
      },
      {
        path: "/user/myProject/edit/:id",
        component: () => import("@/views/myProject/create.vue"),
        meta: { title: "编辑项目" },
        name: "编辑项目",
      },
      {
        path: "/user/myProject/view/:id",
        component: () => import("@/views/myProject/create.vue"),
        meta: { title: "查看项目" },
        name: "查看项目",
      },
      // user
      {
        path: "/user/admin",
        component: () => import("@platform/user/admin"),
        meta: { title: "系统账号管理" },
        name: "系统账号管理",
      },
      {
        path: "/user/create",
        component: () => import("@platform/user/create"),
        meta: { title: "系统账号创建" },
        name: "系统账号创建",
      },
      {
        path: "/user/edit/:id",
        component: () => import("@platform/user/edit"),
        meta: { title: "系统账号编辑" },
        name: "系统账号编辑",
      },
      {
        path: "/user/view/:id",
        component: () => import("@platform/user/view"),
        meta: { title: "系统账号查看" },
        name: "系统账号查看",
      },
      {
        path: "/user/settings",
        component: () => import("@platform/user/settings"),
        meta: { title: "系统账号设置" },
        name: "系统账号设置",
      },
      {
        path: "/user/create2",
        component: () => import("@/views/user/create2"),
        meta: { title: "账号创建" },
        name: "账号创建",
      },
      {
        path: "/user/editMore/:id",
        component: () => import("@/views/user/editMore"),
        meta: { title: "账号编辑" },
        name: "账号编辑",
      },
      {
        path: "/user/viewMore/:id",
        component: () => import("@/views/user/viewMore"),
      },

      // 角色管理

      {
        path: "/role/admin",
        component: () => import("@/views/role/admin"),
        meta: { title: "角色管理" },
        name: " 角色管理 ",
      },
      {
        path: "/role/roleAdmin",
        component: () => import("@/views/role/roleAdmin"),
        meta: { title: "角色管理" },
        name: "角色管理 ",
      },
      {
        path: "/role/create",
        component: () => import("@/views/role/create"),
        meta: { title: "角色创建 " },
        name: "角色创建 ",
      },
      {
        path: "/role/roleCreate",
        component: () => import("@/views/role/roleCreate"),
        meta: { title: "角色创建" },
        name: "角色创建",
      },
      {
        path: "/role/edit/:id",
        component: () => import("@/views/role/edit"),
        meta: { title: "角色编辑 " },
        name: "角色编辑 ",
      },
      {
        path: "/role/roleEdit/:id",
        component: () => import("@/views/role/roleEdit"),
        meta: { title: "角色编辑" },
        name: "角色编辑",
      },
      {
        path: "/role/view/:id",
        component: () => import("@/views/role/view"),
        meta: { title: "角色查看" },
        name: "角色查看 ",
      },
      {
        path: "/role/roleView/:id",
        component: () => import("@/views/role/roleView"),
        meta: { title: "角色查看" },
        name: "角色查看",
      },
    ],
  },
  {
    path: "/contact/wechatInfo",
    component: () => import("@/views/contact/contact/wechatInfo"),
    meta: { title: "客户登记信息" },
    name: "客户登记信息",
  },
  {
    path: "*", // 页面不存在的情况下会跳到404页面
    redirect: "/402",
    name: "notFound",
    hidden: true,
  },
];

// 根据域名检测是否添加game_insight路由
var domainName = window.location.hostname;

if (
  [
    "auth.onemad.com",
    "localhost",
    "performad.onemad.com",
    "beta.onemad.com",
  ].includes(domainName)
) {
  constantRoutes.push({
    path: "/insight",
    component: Layout,
    redirect: "/game_insight",
    meta: { title: "市场洞察" },
    name: "data",
    children: [
      {
        path: "/creative_insight",
        component: () => import("@/views/iq/creativeInsight.vue"),
        name: "creative_insight",
        meta: { title: "创意洞察", keepAlive: true },
      },
      {
        path: "/game_insight",
        component: () => import("@/views/iq/index.vue"),
        name: "game_insight",
        meta: { title: "游戏洞察", keepAlive: true },
      },
      {
        path: "/game_insight/appInfo/:id",
        meta: { title: "应用详情", keepAlive: true },
        name: "game_insight_appInfo",
        component: () => import("@/views/iq/appInfo.vue"),
      },
      {
        path: "/e-online-celebrity/list",
        component: () => import("@/views/e-online-celebrity/list"),
        meta: { title: "网红搜索", keepAlive: true },
        name: "wh-search",
      },
      {
        path: "/e-online-celebrity/detail",
        component: () => import("@/views/e-online-celebrity/detail"),
        meta: { title: "网红详情" },
        name: "网红详情",
      },
      {
        path: "/e-online-celebrity/video-list",
        component: () => import("@/views/e-online-celebrity/video-list"),
        meta: { title: "网红竞品" },
        name: "网红竞品",
      },
      {
        path: "/e-online-celebrity/inventory",
        component: () => import("@/views/e-online-celebrity/inventory"),
        meta: { title: "网红清单" },
        name: "网红清单",
      },
    ],
  });
}

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */

const createRouter = () =>
  new Router({
    // mode: 'history',
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes,
  });

const router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

export default router;
